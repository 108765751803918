$ ->
  preFooter = $('#contact')
  preFooterHeader = preFooter.find('.pre-footer-header')
  preFooterContent = preFooter.find('.pre-footer-content')
  # Floating 'sign up now' button
  $('#floating-button').on 'click', ->
    $('html, body').animate({scrollTop: preFooter.offset().top}, 800)
    preFooterHeader.click() if preFooterContent.height() == 0
  floatingButton = $('#floating-button')[0]
  window.addEventListener 'scroll', ->
    scrollPosition = window.pageYOffset || document.documentElement.scrollTop
    scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
    if scrollPosition >= $('#intro')[0].offsetTop && scrollPosition <= preFooter[0].offsetTop - $('#contact .pre-order-form').height()
      floatingButton.classList.add('visible')
      buttonY = scrollPosition / scrollHeight * (window.innerHeight - 100 - floatingButton.offsetHeight)
      floatingButton.style.transform = "translateY(#{buttonY}px)"
    else
      floatingButton.classList.remove('visible')
      floatingButton.style.transform = "translateY(-100%)"

  # Video logo overlay fading
  $('#promotional-video').on 'play', -> $('#logo-cover').fadeOut(1000)
  $('#promotional-video').on 'pause', -> $('#logo-cover').fadeIn()
  $('#promotional-video').on 'ended', -> $('#logo-cover').fadeIn()
